import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { DsmButton, DsmCheckbox, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { DSM_DARK_GREY, processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { resolvePath } from '../../../../../modules/Helpers/resolvePathFn';
import { DsmCheckboxCustomEvent } from '@dsm-dcs/design-system/dist/types/components';
import { CheckboxChangeEvent } from '@dsm-dcs/design-system/dist/types/types/form-change-event';

type DairyV2EmissionProps = {
  formType: FormType;
  fieldItemPrefix: string;
  setStageFormError: Dispatch<SetStateAction<boolean>>;
};

const DairyV2EmissionInputsComponent: FC<DairyV2EmissionProps> = ({
  formType,
  fieldItemPrefix,
  setStageFormError,
}) => {
  const formContext = useFormContext();
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const [calculatedMethane, setCalculatedMethane] = useState<number|undefined>(
    formContext.getValues(`${fieldItemPrefix}.calculatedMethaneFermentation`)
  );
  const [NOP, setNOP] = useState<boolean>(
    formContext.getValues(`${fieldItemPrefix}.NOP`) as boolean
  );

  const is3NOPSupplementError = () => {
    const hasError = Boolean(
      resolvePath(formContext.errors, `${fieldItemPrefix}.calculatedMethaneFermentation`, null)
    );
    setStageFormError(hasError);
    return hasError;
  }

  const methaneEntericFermentationWatch = useWatch({
    name: `${fieldItemPrefix}.emissionMitigations.methaneEntericFermentation`,
  });

  const get3NOPSupplementText = () => {
    const NOPSupplementText = intl.formatMessage({
      id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION",
    });
    const methanePercentageText = calculatedMethane ? `= ${calculatedMethane}%` : "= ";

    const mountedTextElement = (
      <div style={{ 
        display: "flex", 
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "10px" 
      }}>
        <span
          style={{
            fontWeight: "bold",
            color: "var(--dsm-color-basic-black)",
          }}
        >
          {methanePercentageText}
        </span>
        <span
          style={{
            color: "var(--dsm-color-basic-black)",
            fontWeight: "bold",
            marginLeft: "var(--dsm-spacing-px-1)",
          }}
        >
          ({NOPSupplementText})
        </span>
      </div>
    );

    return mountedTextElement;
  };

  const getErrorText = () => {
    const errorHelperText = intl.formatMessage({
      id: "SUSTELL.STAGE.DAIRY.EMISSIONS.3_NOP_ERROR",
    });

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "15px",
        }}
      >
        <div style={{ display: "flex" }}>
          <ErrorOutlineIcon
            fontSize="small"
            color="error"
            style={{
              marginRight: "var(--dsm-spacing-px-2)",
              alignSelf: "center",
            }}
          />
          <span style={{ 
            color: "var(--dsm-color-error)", 
            fontSize: "10px",
            fontWeight: "bold",
            marginTop: "3px"
          }}>
            {errorHelperText}
          </span>
        </div>
      </div>
    );
  };

  const getTotalMethane = () => {
    const methaneEntericValue = methaneEntericFermentationWatch 
    ? Number(methaneEntericFermentationWatch) 
    : undefined;
    const calculatedMethaneValue = calculatedMethane ? Number(calculatedMethane) : undefined;

    if (methaneEntericValue) {
      if (calculatedMethaneValue) {
        return methaneEntericValue + calculatedMethaneValue;
      } else {
        return methaneEntericValue
      }
    } else if (calculatedMethaneValue) {
      return calculatedMethaneValue;
    }

    return undefined;
  }

  const getTotal3NOPSupplementText = () => {
    const NOPSupplementText = intl.formatMessage({
      id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION",
    });
    
    const totalMethaneValue = getTotalMethane();

    const totalPercentageText = totalMethaneValue ? `= ${totalMethaneValue}%` : "= ";

    const mountedTextElement = (
      <div style={{ 
        display: "flex", 
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "10px" 
      }}>
        <span
          style={{
            fontWeight: "bold",
            color: "var(--dsm-color-basic-black)",
            fontSize: "18px",
          }}
        >
          {totalPercentageText}
        </span>
        <span
          style={{
            fontWeight: "bold",
            color: "var(--dsm-color-basic-black)",
            fontSize: "18px",
            marginLeft: "var(--dsm-spacing-px-1)",
          }}
        >
          ({"Total " + NOPSupplementText})
        </span>
      </div>
    );

    return mountedTextElement;
  };

  const updateInputDisplay = (e: DsmCheckboxCustomEvent<CheckboxChangeEvent>) => {
    const newState = e.target?.checked;
    formContext.setValue(`${fieldItemPrefix}.NOP`, newState);
    setNOP(newState);
  }

  useEffect(() => {
    if (!NOP) {
      formContext.setValue(`${fieldItemPrefix}.NOPdose`, undefined);
      formContext.clearErrors(`${fieldItemPrefix}.NOPdose`);
      formContext.setValue(`${fieldItemPrefix}.averageFatDMI`, undefined);
      formContext.clearErrors(`${fieldItemPrefix}.averageFatDMI`);
      formContext.setValue(`${fieldItemPrefix}.cowsFedSupplement`, undefined);
      formContext.clearErrors(`${fieldItemPrefix}.cowsFedSupplement`);
      setCalculatedMethane(undefined);
      setStageFormError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NOP]);

  useEffect(() => {
    formContext.setValue(`${fieldItemPrefix}.totalMethaneFermentation`, getTotalMethane());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methaneEntericFermentationWatch, calculatedMethane]);

  useEffect(() => {
    formContext.setValue(`${fieldItemPrefix}.calculatedMethaneFermentation`, calculatedMethane);
    formContext.trigger(`${fieldItemPrefix}.calculatedMethaneFermentation`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedMethane]);

  return (
    <div style={{ display: "flex", flexDirection: "column", marginBottom: '16px' }}>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.calculatedMethaneFermentation`}
        value={formContext.getValues(`${fieldItemPrefix}.calculatedMethaneFermentation`)}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.totalMethaneFermentation`}
        value={formContext.getValues(`${fieldItemPrefix}.totalMethaneFermentation`)}
      />
      <DsmGrid className={classes.additionalEntriesMMSBlockHolder}>
        <DsmCheckbox
          name={`${fieldItemPrefix}.NOP`}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.NOP`) as string
          }
          checked={formContext.getValues(`${fieldItemPrefix}.NOP`) as boolean}
          disabled={formType === FormType.View}
          onDsmChange={(e) => updateInputDisplay(e)}
          label={intl.formatMessage({
            id: `BASELINE.FORM.EMISSIONS.3_NOP_SUPPLEMENTS`,
          })}
          style={{
            marginBottom: '16px',
          }}
        />
        {NOP && (
          <div>
            <ReactHookDsmInput
              label={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.EMISSIONS.3_NOP_DOSE",
              })}
              name={`${fieldItemPrefix}.NOPdose`}
              adornment={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.EMISSIONS.3_NOP_DOSE.ADORNMENT",
              })}
              placeholder={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.EMISSIONS.3_NOP_DOSE.PLACEHOLDER",
              })}
              type="number"
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.EMISSIONS.3_NOP_DOSE.TOOLTIP",
              })}
              defaultValue={
                formContext.getValues(
                  `${fieldItemPrefix}.NOPdose`
                ) as string
              }
              required
            />
            <ReactHookDsmInput
              label={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.EMISSIONS.AVERAGE_FAT",
              })}
              name={`${fieldItemPrefix}.averageFatDMI`}
              adornment={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.EMISSIONS.AVERAGE_FAT.ADORNMENT",
              })}
              placeholder={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.EMISSIONS.AVERAGE_FAT.PLACEHOLDER",
              })}
              type="number"
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.EMISSIONS.AVERAGE_FAT.TOOLTIP",
              })}
              defaultValue={
                formContext.getValues(
                  `${fieldItemPrefix}.averageFatDMI`
                ) as string
              }
              required
            />
            <ReactHookDsmInput
              label={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.EMISSIONS.COWS_FED_SUPPLEMENT",
              })}
              name={`${fieldItemPrefix}.cowsFedSupplement`}
              adornment={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.EMISSIONS.COWS_FED_SUPPLEMENT.ADORNMENT",
              })}
              type="number"
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.EMISSIONS.COWS_FED_SUPPLEMENT.TOOLTIP",
              })}
              defaultValue={
                formContext.getValues(
                  `${fieldItemPrefix}.cowsFedSupplement`
                ) as string
              }
              required
            />
            <DsmButton
              widthFull
              variant="text"
              onClick={() => setCalculatedMethane(Math.floor(Math.random() * 101))}
              disabled={formType === FormType.View}
              style={{ marginBottom: is3NOPSupplementError() ? "8px" : "21px" }}
            >
              <DsmIcon slot="before" name="education/calculator" />
              <u style={{ fontSize: "16px" }}>
                {intl.formatMessage({
                  id: 'BOVAER.ERROR.CALCULATE',
                })}
              </u>
            </DsmButton>
            {is3NOPSupplementError() && getErrorText()}
            <hr
              style={{
                borderWidth: "1px",
                borderColor: DSM_DARK_GREY,
                width: "100%",
                margin: 0,
                padding: 0,
              }}
            />
            {get3NOPSupplementText()}
          </div>
        )}
      </DsmGrid>
      {NOP && (
        <div>
          {getTotal3NOPSupplementText()}
          <hr
            style={{
              borderWidth: "1px",
              borderColor: DSM_DARK_GREY,
              width: "100%",
              margin: 0,
              padding: 0,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DairyV2EmissionInputsComponent;
