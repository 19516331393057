/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC, useEffect, useState } from 'react';
import {
  DsmButton,
  DsmGrid,
  DsmIcon,
  DsmModal,
} from '@dsm-dcs/design-system-react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Tooltip } from '@material-ui/core';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import {
  CSSClassesList,
  ReactChangedType,
} from '../../../../helpers/helperTypes';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import FeedsFormCommonDataPart from '../../CommonDataParts/FeedsFormCommonPart';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import {
  DatabaseFoundation,
  FeedStatus,
} from '../../../../../../graphql/generated/blonk/cattle';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { getDatabaseOptions } from '../../../CompoundFeedWizard/utils';
import { DsmButtonV2, useButtonStyles } from '../../../helpers/DsmButton';
import { CompoundFeedNameDatabase, Origin, SingleIngredient } from '../types';
import {
  DairyV2ContinuousGrowingStageData,
  DairyV2DairyCowStageData,
  DairyV2Stage,
} from '../../../../models/Baseline/DairyV2Baseline';
import { BaselineFeed } from '../../../../models/Baseline';
import { Maybe, StageType } from '../../../../../../graphql/types';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../utils/obj-utils';
import DairyV2FeedFreshGrassComponent from './DairyV2FeedFreshGrassComponent';
import { defaultValues } from '../../baselineDefaultValues';
import DairyV2FeedMothersMilkComponent from './DairyV2FeedMothersMilkComponent';
import { getInitialDatabaseValue } from '../../../../helpers/initialValues';

interface SelectedDatabase {
  open: boolean;
  defaultValue: DatabaseFoundation;
  selectedValue: DatabaseFoundation | null;
}

const dairyV2FeedStageDefaultValues = defaultValues.DAIRY_V2.stages[0].stageData.feed;

type DairyV2FeedComponentProps = {
  formType: FormType;
  fieldItemPrefix: string;
  stageIndex?: number;
  compoundFeeds: Maybe<Array<CompoundFeedNameDatabase>>;
  singleIngredients: Maybe<Array<SingleIngredient>>;
  formVisible: boolean | undefined;
  origins: Maybe<Array<Origin>>;
  stageType?: StageType
};

const DairyV2FeedComponent: FC<DairyV2FeedComponentProps> = ({
  formType,
  fieldItemPrefix,
  stageIndex = 0,
  compoundFeeds,
  singleIngredients,
  formVisible,
  origins,
  stageType = StageType.ContinuousGrowing
}) => {
  const intl = useIntl();
  const formContext = useFormContext();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const buttonClasses = useButtonStyles();
  const userProfile = UserProfilePrefs.getInstance();
  const [compoundFeedsData, setCompoundFeedData] = useState(compoundFeeds);
  const [singleIngredientsData, setSingleIngredientsData] =
    useState(singleIngredients);
  const isContinuousGrowingStage = stageType === StageType.ContinuousGrowing;

  const {
    fields: freshGrass,
    append: appendFreshGrass,
    remove: removeFreshGrass,
  } = useFieldArray({
    name: `${fieldItemPrefix}.freshGrass`,
    control: formContext.control,
    keyName: 'keyId',
  });

  const [shouldDisplayResetButton, setShouldDisplayResetButton] = useState(
    freshGrass && freshGrass.some((grass) => grass.type)
  );

  useEffect(() => {
    setCompoundFeedData(compoundFeeds);
    setSingleIngredientsData(singleIngredients);
  }, [compoundFeeds, singleIngredients]);

  const baselineDatabaseFoundation =
    getInitialDatabaseValue<DatabaseFoundation>(
      formContext,
      'info',
      userProfile
    );
  const defaultDatabaseFoundation = baselineDatabaseFoundation;
  const [
    openDatabaseSelectionWaringDialog,
    setOpenDatabaseSelectionWaringDialog,
  ] = useState<SelectedDatabase>({
    open: false,
    defaultValue: defaultDatabaseFoundation,
    selectedValue: null,
  });

  const handleChangeDatabaseFoundation = (event: ReactChangedType) => {
    if (event.stopPropagation) event.stopPropagation();
    const option = event.target.value as DatabaseFoundation | null;
    if (baselineDatabaseFoundation !== option) {
      setOpenDatabaseSelectionWaringDialog({
        open: true,
        selectedValue: option,
        defaultValue: defaultDatabaseFoundation,
      });
    }
  };

  const closeDialog = () => {
    setOpenDatabaseSelectionWaringDialog({
      open: false,
      selectedValue: null,
      defaultValue: defaultDatabaseFoundation,
    });
  };

  const getCompoundFeedsList = (databaseOption: DatabaseFoundation) =>
    compoundFeeds?.filter(
      (feed: CompoundFeedNameDatabase) =>
        feed.databaseFoundation === databaseOption
    );

  const handleDiscard = () => {
    getCompoundFeedsList(openDatabaseSelectionWaringDialog.defaultValue);
    formContext.setValue(
      `info.databaseFoundation`,
      openDatabaseSelectionWaringDialog.defaultValue
    );
    closeDialog();
  };

  const updateContinuousGrowingFeeds = (stage: DairyV2Stage, index: number) => {
    const feeds = (stage.stageData as DairyV2ContinuousGrowingStageData).feed.compoundFeeds;
    const updatedFeeds: BaselineFeed[] | undefined = feeds?.map(() => ({
      feedType: '',
      kgPerAnimal: 0,
    }));
    formContext.setValue(
      `stages.${index}.stageData.feed.compoundFeeds`,
      updatedFeeds
    );
  };

  const updateFeeds = (stage: DairyV2Stage, index: number) => {
    const feeds = (stage.stageData as DairyV2DairyCowStageData).feed.compoundFeeds;
    const updatedFeeds: BaselineFeed[] | undefined = feeds?.map(() => ({
      feedType: '',
      kgPerAnimal: 0,
    }));
    formContext.setValue(
      `stages.${index}.stageData.feed.compoundFeeds`,
      updatedFeeds
    );
  };

  const handleConfirm = () => {
    closeDialog();
    getCompoundFeedsList(baselineDatabaseFoundation);
    const stages = formContext.getValues('stages');
    stages.forEach((stage: DairyV2Stage, i: number) => {
      if (i === stageIndex) {
        if (stage.type === StageType.ContinuousGrowing) updateContinuousGrowingFeeds(stage, i);
        else updateFeeds(stage, i);
      }
    });
    formContext.setValue(`stages.${stageIndex}.stageData.input.internalSources`, []);
    formContext.trigger(`stages.${stageIndex}.stageData.input`);
  };

  const dietCharacterisationOptions = () =>
    enumToOptionsArrayWithTranslatedStrings(
      FeedStatus,
      intl,
      'SUSTELL.STAGE.DAIRY.FEED'
    );

  const resetToDefaultValues = () => {
    freshGrass.forEach((_, index)=> {
      formContext.setValue(
        `${fieldItemPrefix}.freshGrass.${index}.grossEnergyContent`,
        dairyV2FeedStageDefaultValues.freshGrass[0].grossEnergyContent
      );
      formContext.clearErrors(
        `${fieldItemPrefix}.freshGrass.${index}.grossEnergyContent`
      );
      formContext.setValue(
        `${fieldItemPrefix}.freshGrass.${index}.ashContent`,
        dairyV2FeedStageDefaultValues.freshGrass[0].ashContent
      );
      formContext.clearErrors(
        `${fieldItemPrefix}.freshGrass.${index}.ashContent`
      );
    });
  };

  const updateShouldDisplayResetButton = () => {
    setShouldDisplayResetButton(freshGrass && freshGrass.some((_, index) => 
      formContext.getValues(`${fieldItemPrefix}.freshGrass.${index}.type`)
    ));
  }

  return (
    <div>
      {openDatabaseSelectionWaringDialog.open && (
        <DsmModal
          open={openDatabaseSelectionWaringDialog.open}
          icon="general/info-square"
          header={intl.formatMessage({
            id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION_WARNING_HEADER',
          })}
          onDsmClosed={() => closeDialog()}
        >
          <p slot="content">
            {intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION_WARNING_BODY',
            })}
          </p>
          <>
            <DsmButtonV2
              slot="actions"
              onClick={handleDiscard}
              className={buttonClasses.secondaryButton}
            >
              {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
            </DsmButtonV2>
            <DsmButtonV2
              variant="secondary"
              slot="actions"
              onClick={handleConfirm}
            >
              {intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
            </DsmButtonV2>
          </>
        </DsmModal>
      )}
      <DsmGrid
        className={classes.dsmGridTwoColumnNoRowSpacing}
        style={{
          grid: 'auto/280px 520px',
          marginTop: '16px',
          marginBottom: '17px',
        }}
      >
        <ReactHookDsmSelect
          name="info.databaseFoundation"
          label={`${intl.formatMessage({
            id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION',
          })}`}
          disabled={formType === FormType.View || formType === FormType.Edit}
          defaultValue={defaultDatabaseFoundation}
          options={getDatabaseOptions()}
          changeHandler={(e: ReactChangedType) =>
            handleChangeDatabaseFoundation(e)
          }
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELECTION.TOOLTIP',
          })}
          required
        />
        <ReactHookDsmSelect
          name={`${fieldItemPrefix}.dietCharacterisation`}
          label={`${intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.FEED.DIET_CHARACTERISATION',
          })}`}
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(
              `${fieldItemPrefix}.dietCharacterisation`
            ) as string
          }
          options={dietCharacterisationOptions()}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.FEED.DIET_CHARACTERISATION.TOOLTIP',
          })}
          required
        />
      </DsmGrid>
      <FeedsFormCommonDataPart
        formType={formType}
        formVisible={formVisible}
        itemIndex={stageIndex}
        fieldItemPrefix="feed"
        compoundFeeds={compoundFeedsData}
        singleIngredients={singleIngredientsData}
        origins={origins}
        keyCompoundFeeds={`${fieldItemPrefix}CompoundFeeds`}
        keySingleIngredients={`${fieldItemPrefix}SingleIngredients`}
      />
      {isContinuousGrowingStage && (
        <DairyV2FeedMothersMilkComponent
          fieldItemPrefix={`${fieldItemPrefix}.mothersMilk`}
          formType={formType}
        />
      )}
      {freshGrass &&
        freshGrass.map((item, index) => (
          <DairyV2FeedFreshGrassComponent
            key={item.keyId}
            fieldItemPrefix={`${fieldItemPrefix}.freshGrass`}
            formType={formType}
            itemIndex={index}
            removeHandler={(index) => removeFreshGrass(index)}
            shouldDisplayResetButton={updateShouldDisplayResetButton}
          />
        ))}
      <DsmButton
        variant="text"
        onClick={() =>
          appendFreshGrass({
            ...defaultValues.DAIRY_V2.stages[0].stageData.feed.freshGrass,
          })
        }
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        {intl.formatMessage({ id: 'GENERAL.ADD.ANOTHER' })}{' '}
        {intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.FEED.GRASS' })}
      </DsmButton>
      {shouldDisplayResetButton && (
        <div className={classes.resetButtonContainer}>
          <DsmButton
            widthFull
            variant="text"
            onClick={() => resetToDefaultValues()}
            disabled={formType === FormType.View}
            className="mt-3"
          >
            <DsmIcon slot="before" name="arrows/refresh-cw-01" />
            <u style={{ textDecoration: 'none' }}>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.RESET_DEFAULT_VALUES',
              })}
            </u>
          </DsmButton>
          <Tooltip
            title={intl.formatMessage({
              id: 'SUSTELL.RESET_DEFAULT.TOOLTIP',
            })}
            placement="left"
            className='ml-3'
            style={{transform: 'translateY(85%)'}}
          >
            <DsmIcon
              name="general/help-circle"
              className={classes.inlineTooltipClass}
              title=""
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

DairyV2FeedComponent.defaultProps = {
  stageIndex: 0,
  stageType: StageType.ContinuousGrowing 
};

export default DairyV2FeedComponent;
