import { FC } from 'react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

type AlertBoxProps = {
  messageId: string;
};

// a static dialog loading and showing descirption text
const AlertBox: FC<AlertBoxProps> = ({messageId}) => {

  const intl = useIntl();

  return (
    <Grid
        container
        direction="column"
        spacing={1}
    >
        <Grid item xs={12}>
        <Alert severity="error">
            <AlertTitle>
            {intl.formatMessage({ id: messageId })}
            </AlertTitle>
        </Alert>
        </Grid>
    </Grid>
	);
};

export default AlertBox;